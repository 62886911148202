import { type FC, memo, useState, useCallback, useEffect } from 'react'
import { signOut } from 'firebase/auth'
import { useAuthState } from 'react-firebase-hooks/auth'
import { Typography, Button } from '@mui/material'
import { auth } from '../firebase'
import { helloWorld } from '../functions'
import { Center } from '../components'

const Home: FC = () => {
    const [user] = useAuthState(auth)
    const [message, setMessage] = useState('...')

    const fetchMessage = useCallback(async () => {
        try {
            const result = await helloWorld()
            setMessage(result.data.message)
        } catch (error) {
            if (error instanceof Error) {
                console.log(error.message)
            }
        }
    }, [])

    const handleSignOut = useCallback(async () => {
        try {
            await signOut(auth)
        } catch (error) {
            if (error instanceof Error) {
                console.log(error.message)
            }
        }
    }, [])

    useEffect(() => {
        void fetchMessage()
    }, [])

    return (
        <>
            <Center>
                <Typography
                    variant='h5'
                    sx={{ marginBottom: '10px' }}
                >
                    Welcome {user?.displayName}
                </Typography>
                <Typography
                    variant='h6'
                    sx={{ marginBottom: '10px' }}
                >
                    {message}
                </Typography>
                <Button
                    variant='contained'
                    onClick={handleSignOut}
                >
                Sign Out
                </Button>
            </Center></>
    )
}

export default memo(Home)
