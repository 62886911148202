import { type FC, type FormEvent, memo, useCallback, useEffect, useState } from 'react'
import { isSignInWithEmailLink, sendSignInLinkToEmail, signInWithEmailLink, signInWithRedirect } from 'firebase/auth'
import { Button, Divider, TextField, Grid, Snackbar, Alert } from '@mui/material'
import { auth, googleAuthProvider } from '../../firebase'
import { Center } from '../../components'
import './SignInForm.css'
import { FcGoogle } from 'react-icons/fc'
import { IconContext } from 'react-icons'
import { useNavigate } from 'react-router-dom'

const SignInForm: FC = () => {
    const [email, setEmail] = useState('')
    const [loginLoading, setLoginLoading] = useState(false)
    const [infoSnackbarOpen, setInfoSnackbarOpen] = useState(false)
    const [loginError, setLoginError] = useState('')

    const navigate = useNavigate()

    const checkEmailValidiation = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        const rgExp = /^[a-zA-Z0-9._]+@[a-zA-Z]+\.[a-zA-Z]{2,6}$/
        if (rgExp.test(email)) {
            handleEmailLogin()
        } else if (email === '') {
            setLoginError('You must enter an email to continue')
        } else if (!rgExp.test(email)) {
            setLoginError('Please enter a valid email')
        }
    }

    useEffect(() => {
        const handleSignInWithEmailLink = async () => {
            if (isSignInWithEmailLink(auth, window.location.href)) {
                let email = window.localStorage.getItem('emailForSignIn')

                if (email === null) {
                    email = window.prompt('Please provide your email for confirmation')
                }

                if (email === null) {
                    return
                }

                try {
                    await signInWithEmailLink(auth, email, window.location.href)
                    localStorage.removeItem('emailForSignIn')
                    navigate(window.location.pathname)
                } catch (error) {
                    if (error instanceof Error) {
                        console.log(error.message)
                    }
                }
            }
        }
        // not signed in
        void handleSignInWithEmailLink()
    }, [])

    const handleGoogleSignIn = useCallback(() => {
        void signInWithRedirect(auth, googleAuthProvider)
    }, [])

    // const handleMicrosoftSignIn = useCallback(() => {
    //     void signInWithRedirect(auth, microsoftAuthProvider)
    // }, [])

    const handleEmailLogin = useCallback(() => {
        setLoginLoading(true)
        setLoginError('')
        void sendSignInLinkToEmail(auth, email, {
            url: window.location.href,
            handleCodeInApp: true
        }).then(() => {
            window.localStorage.setItem('emailForSignIn', email)
            setInfoSnackbarOpen(true)
        }).catch((error) => {
            console.log(error.message)
        }).finally(() => {
            setLoginLoading(false)
        })
    }, [email])

    const toggleInfoSnackbar = useCallback(() => {
        setInfoSnackbarOpen((infoSnackbarOpen) => !infoSnackbarOpen)
    }, [])

    return (
        <Center>
            <Grid width={350} maxWidth={350}>
                <Grid container className="loginStyle">
                    <h1>Welcome Back</h1>
                </Grid>
                <Grid container className="loginStyle" spacing={2} component='form' onSubmit={checkEmailValidiation}>
                    <Grid item xs={12}>
                        <TextField
                            error={Boolean(loginError)}
                            helperText={loginError}
                            className="signInButton"
                            type='email'
                            label="Email address"
                            color='success'
                            value={email}
                            onChange={(e) => {
                                setEmail(e.target.value)
                            }}
                        />
                    </Grid>
                    <Grid container item xs={12}>
                        <Button className="signInButton" variant='contained' color='success' type='submit' disabled={loginLoading}>
                            {loginLoading ? ' LOADING...' : 'CONTINUE'}
                        </Button>
                    </Grid>
                </Grid>
                <Grid>
                    <Divider>OR</Divider>
                </Grid>
                <Grid className="loginStyle" container spacing={1}>
                    <IconContext.Provider value={{ size: '1.5rem' }}>
                        <Grid item xs={12}>
                            <Button startIcon={<FcGoogle />} className="signInButton" onClick={handleGoogleSignIn}>
                                Continue with Google
                            </Button>
                        </Grid>
                        {/* <Grid item md={7} sm={7} xs={7}>
                            <Button startIcon={<BsMicrosoft/>} className="signInButton" onClick={handleMicrosoftSignIn}>
                                CONTINUE WITH Microsoft
                            </Button>
                        </Grid> */}
                    </IconContext.Provider>
                </Grid>
            </Grid>
            <Snackbar open={infoSnackbarOpen} autoHideDuration={6000} onClose={toggleInfoSnackbar}>
                <Alert onClose={toggleInfoSnackbar} severity="success">
                    A sign in link has been sent to your email
                </Alert>
            </Snackbar>
        </Center>
    )
}

export default memo(SignInForm)
