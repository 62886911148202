import { initializeApp } from 'firebase/app'
import { getAuth, GoogleAuthProvider, OAuthProvider } from 'firebase/auth'
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions'
import { nodeEnv, firebaseConfig } from './config'

export const app = initializeApp(firebaseConfig)

export const auth = getAuth(app)

export const googleAuthProvider = new GoogleAuthProvider()
export const microsoftAuthProvider = new OAuthProvider('microsoft.com')

export const functions = getFunctions(app)

if (nodeEnv === 'development') {
    connectFunctionsEmulator(functions, 'localhost', 5001)
}
