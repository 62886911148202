import { type FC, memo } from 'react'
import { Routes, Route } from 'react-router-dom'
import { ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import { Protected } from './containers'
import { NotFound, Home } from './pages'
import { theme } from './config'

const App: FC = () => {
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Routes>
                <Route
                    index
                    element={
                        <Protected>
                            <Home />
                        </Protected>
                    }
                />
                <Route path='*' Component={NotFound} />
            </Routes>
        </ThemeProvider>
    )
}

export default memo(App)
